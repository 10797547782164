// i18next-extract-mark-ns-start bizum-for-wix
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import wix_bizum from 'images/wix_bizum.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {
  InternalPageLink,
  PaymentsOrchestrationLink,
  RedsysAlternativeLink
} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="bizum-for-wix"
        title={t('Bizum for Wix E-commerce')}
        description={t(
          'Add Bizum to your Wix e-commerce store to let your customers pay with one of the most popular mobile payment apps in Spain. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Add Bizum to your Wix e-commerce store</Trans>
              </SectionHeader>
              <Trans parent="p">
                Bizum’s popular peer-to-peer mobile payment app has over one dozen million active
                users, and counting. It’s quickly gaining traction in e-commerce, too. More than
                11,000 online merchants already accept Bizum payments. Stay competitive and boost
                customer satisfaction by adding Bizum to your Wix e-commerce store.{' '}
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={wix_bizum} alt="Wix bizum" title="Wix bizum" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept Bizum payments and many other Wix payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> to
                your Wix store will help you reach more customers, but it shouldn’t be the only{' '}
                <InternalPageLink slug="payment-methods">online payment method</InternalPageLink>{' '}
                you accept. Online shoppers look for a range of payment options to choose from. This
                includes <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                ,{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , and more <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                such as <InternalPageLink slug="payment-methods/bizum">Bizum </InternalPageLink> in
                Spain.
              </Trans>
              <Trans parent="p">
                Adding many payment methods to your Wix store will help improve the checkout
                experience and convert website visitors into loyal paying customers. That’s why we
                combine all major and alternative payment methods into a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Improve sales revenue with payments orchestration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Boost your e-commerce sales with a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment experience</BlogLink>{' '}
                that results in more approved payments. With our{' '}
                <PaymentsOrchestrationLink>payments orchestration</PaymentsOrchestrationLink>{' '}
                feature, you don’t have to rely on{' '}
                <RedsysAlternativeLink>Redsys</RedsysAlternativeLink> alone. Instead, you can set
                payment processing rules. Choose a default payment processor and if it fails, we’ll
                send the payment to another one. This will reduce{' '}
                <BlogLink slug="online-payment-failure-message">payment failure messages</BlogLink>,
                and raise your conversion rate.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Pay the lowest Wix payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Save money that you can reinvest to grow your e-commerce store thanks to our dynamic
                pricing model. As you sell more, your transaction fees will go down instantaneously.
              </Trans>
              <Trans parent="p">
                Choose a <Link to="/wix-payment-gateway/">Wix payment gateway</Link> that scales
                with your business — <Link to="/pricing/">view pricing here</Link>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Set up your Wix website payment gateway integration</Trans>
            </SectionHeader>
            <Trans parent="p">
              Integrating your Wix store with our payment gateway takes a few minutes. Follow these
              steps to start accepting Bizum (and more payment methods) in your Wix e-commerce store
              today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/360018047698-How-do-I-activate-the-Bizum-payment-method-">
                  Activate Bizum payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/wix/">
                  Wix integration guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
              <Trans parent="li">
                Review our{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/articles/4406122571025-How-to-enable-MONEI-s-payment-gateway-in-Wix-">
                  Wix support article here
                </AnchorLink>
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "bizum-for-wix"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
